import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Layout({ children, pages }) {
  const location = useLocation();
  const logoUrl = process.env.REACT_APP_LOGO_URL;
  const phoneNumber = process.env.REACT_APP_PHONE_NUMBER;
  const footerText = process.env.REACT_APP_FOOTER_TEXT;
  const footerImageUrl = process.env.REACT_APP_FOOTER_IMAGE_URL; // Load the footer image URL from the env

  return (
    <div className="flex flex-col min-h-screen">
      <header className="bg-gray-800 p-5 text-white">
        <div className="container mx-auto flex justify-between items-center">
          <img src={logoUrl} alt="Logo" className="h-12 w-auto" />
          <a href={`tel:${phoneNumber}`} className="text-2xl font-bold bg-blue-600 px-4 py-2 rounded">
            {phoneNumber}
          </a>
        </div>
      </header>

      <div className="flex flex-grow">
        <aside className="w-64 bg-gray-100 p-6">
          <nav>
            <ul className="space-y-4">
              <li><Link to="/" className={`font-bold ${location.pathname === '/' ? 'text-blue-600' : 'text-gray-700'}`}>Home</Link></li>
              <li><Link to="/about" className={`font-bold ${location.pathname === '/about' ? 'text-blue-600' : 'text-gray-700'}`}>About</Link></li>
              <li><Link to="/contact" className={`font-bold ${location.pathname === '/contact' ? 'text-blue-600' : 'text-gray-700'}`}>Contact</Link></li>
              <li className="font-bold text-gray-800 mt-4">Locations</li>
              {pages.map((page, index) => (
                <li key={index}>
                  <Link 
                    to={page.path} 
                    className={`${location.pathname === page.path ? 'text-blue-600' : 'text-gray-700'}`}
                  >
                    {page.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </aside>

        <main className="flex-grow p-8">
          {children}
        </main>
      </div>

      <footer className="bg-gray-800 text-white p-4">
        <div className="container mx-auto text-center">
          <p>{footerText}</p>
        </div>
      </footer>
    </div>
  );
}

export default Layout;
