import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

function Contact() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  useEffect(() => {
    const envPhoneNumber = process.env.REACT_APP_PHONE_NUMBER || 'Not available';
    setPhoneNumber(envPhoneNumber);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the form data to your backend
    console.log('Form submitted:', formData);
    // Reset form after submission
    setFormData({ name: '', email: '', message: '' });
    alert('Thank you for your message. We will get back to you soon!');
  };

  return (
    <>
      <Helmet>
        <title>Contact Us - Recovery Center</title>
        <meta name="description" content="Contact our Recovery Center for support and information. We're here to help you on your journey to wellness." />
      </Helmet>
      <div className="flex items-center justify-center min-h-screen p-8">
        <div className="max-w-3xl w-full">
          <h1 className="text-4xl font-bold mb-6 text-center">Contact Us</h1>
          
          <p className="mb-4 text-center">
            Have questions or need assistance? Reach out to us, and our team will be happy to assist you.
          </p>

          <div className="mb-8 text-center">
            <strong>Phone:</strong> <a href={`tel:${phoneNumber}`} className="text-blue-600 hover:underline">{phoneNumber}</a>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-lg">Your Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
                placeholder="John Doe"
                required
                aria-required="true"
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-lg">Your Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
                placeholder="you@example.com"
                required
                aria-required="true"
              />
            </div>

            <div>
              <label htmlFor="message" className="block text-lg">Your Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
                rows="5"
                placeholder="How can we help you?"
                required
                aria-required="true"
              ></textarea>
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="bg-blue-600 text-white px-6 py-3 rounded hover:bg-blue-700 transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Contact;