import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Home() {
  const siteName = process.env.REACT_APP_SITE_NAME || 'Recovery Center';
  const backgroundImageUrl = process.env.REACT_APP_BACKGROUND_IMAGE_URL || '/default-background.jpg';

  return (
    <>
      <Helmet>
        <title>{siteName} - Your Journey to Recovery Starts Here</title>
        <meta name="description" content={`Welcome to ${siteName}. We're here to support you on your journey to recovery and wellness.`} />
        <meta name="keywords" content="recovery, wellness, support, healing" />
      </Helmet>
      <div 
        className="flex items-center justify-center min-h-screen bg-cover bg-center" 
        style={{ backgroundImage: `url("${backgroundImageUrl}")` }}  // Add quotes around the URL
        role="main"
      >
        <div className="bg-black bg-opacity-50 p-10 rounded-lg text-center max-w-2xl">
          <h1 className="text-4xl sm:text-5xl text-white font-bold mb-4">Welcome to {siteName}</h1>
          <p className="text-lg sm:text-xl text-white mb-6">Your journey to recovery starts here. We're here to support you every step of the way.</p>
          <Link 
            to="/about" 
            className="inline-block bg-green-500 text-white px-6 py-3 rounded-lg hover:bg-green-600 transition duration-200 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50"
          >
            Learn More
          </Link>
        </div>
      </div>
    </>
  );
}

export default Home;
