import React from 'react';
import { Helmet } from 'react-helmet';

function About() {
  const siteName = process.env.REACT_APP_SITE_NAME || 'Recovery Center';

  return (
    <>
      <Helmet>
        <title>About {siteName} - Comprehensive Addiction Treatment</title>
        <meta name="description" content={`Learn about ${siteName}'s personalized addiction treatment programs, dual diagnosis approach, and supportive group therapy sessions.`} />
      </Helmet>
      <div className="flex flex-col items-center justify-center min-h-screen p-8 bg-gray-100">
        <div className="max-w-3xl bg-white p-8 rounded-lg shadow-md">
          <h1 className="text-4xl font-bold mb-6 text-center text-blue-600">{siteName}</h1>
          
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">Our Approach</h2>
            <p className="mb-4 text-gray-700 leading-relaxed">
              At {siteName}, we offer a comprehensive and personalized addiction treatment program tailored to your unique needs. Our caring and compassionate professionals are dedicated to guiding you through every step of your recovery journey.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">Acknowledging the Challenge</h2>
            <p className="mb-4 text-gray-700 leading-relaxed">
              The first crucial step is acknowledging the problem. We understand that admitting you need help can be challenging, but it's a brave and necessary step towards recovery. Our team is here to support you in overcoming denial and embracing professional treatment.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">Dual Diagnosis Approach</h2>
            <p className="mb-4 text-gray-700 leading-relaxed">
              We recognize that addiction often coexists with other mental health issues. Our dual diagnosis mental health treatment ensures that we address all aspects of your well-being, providing you with comprehensive care for the best chance at long-term recovery.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">Supportive Group Therapy</h2>
            <p className="mb-4 text-gray-700 leading-relaxed">
              Our group therapy sessions offer a unique opportunity to share experiences, learn from others, and build a supportive community. These sessions foster empathy, inspiration, and motivation, crucial elements in your recovery journey.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">Our Commitment to You</h2>
            <p className="mb-4 text-gray-700 leading-relaxed">
              At {siteName}, we're committed to providing a healing atmosphere conducive to recovery. We emphasize honesty and openness, as these are fundamental to your treatment's success and to building strong, supportive relationships within our community.
            </p>
          </section>

          <div className="mt-8 text-center">
            <a href="/contact" className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition duration-300 inline-block focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
              Contact Us for Help
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;