import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Layout from './components/Layout';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/contact';
import axios from 'axios';

function HTMLPage({ url, pageName }) {
  const [content, setContent] = useState('');
  const [pageError, setPageError] = useState(null);

  useEffect(() => {
    async function fetchHTML() {
      try {
        const response = await axios.get(url);
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(response.data, 'text/html');
        const body = htmlDoc.body;
        setContent(body.innerHTML);
      } catch (error) {
        console.error('Failed to fetch HTML content:', error);
        setPageError(error.message);
      }
    }

    fetchHTML();
  }, [url]);

  if (pageError) return <div>Error loading page: {pageError}</div>;
  return (
    <>
      <Helmet>
        <title>{pageName} | Perry Recovery</title>
        <meta name="description" content={`Information about ${pageName} at Perry Recovery`} />
      </Helmet>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </>
  );
}

function AppContent() {
  const [pages, setPages] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    async function fetchPages() {
      setIsLoading(true);
      try {
        const response = await axios.post('https://octosite-backend.onrender.com/list-s3-files', {
          bucket: 'octosite',
          folder: process.env.REACT_APP_S3_FOLDER // Use env variable here
        });
  
        console.log('API Response:', response.data);
  
        const files = response.data;
  
        const formattedPages = files.map((fileUrl) => {
          const urlParts = fileUrl.split('/');
          const fileName = urlParts[urlParts.length - 1].split('?')[0];
          const pageName = fileName.replace('.html', '').replace(/[_-]/g, ' ');
          const pathName = pageName.toLowerCase().replace(/ /g, '-');
  
          return {
            name: pageName,
            path: `/${pathName}`,
            url: fileUrl,
          };
        });
  
        console.log('Formatted Pages:', formattedPages);
  
        setPages(formattedPages);
      } catch (error) {
        console.error('Failed to fetch pages:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    }
  
    fetchPages();
  }, []);
  

  console.log('App render. isLoading:', isLoading, 'error:', error, 'pages:', pages);
  console.log('Current location:', location);

  if (isLoading) return <Layout pages={pages}><div>Loading...</div></Layout>;
  if (error) return <Layout pages={pages}><div>Error: {error}</div></Layout>;

  return (
    <Layout pages={pages}>
      <Helmet>
        <title>Perry Recovery</title>
        <meta name="description" content="Perry Recovery - Helping you on your journey to wellness" />
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        {pages.map((page, index) => (
          <Route
            key={index}
            path={page.path}
            element={<HTMLPage url={page.url} pageName={page.name} />}
          />
        ))}
        <Route path="*" element={<div>Page not found</div>} />
      </Routes>
    </Layout>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;